import { LoginDetails, UserDetails } from "./AuthInterface";

export class DataPrepare{

    prepareLoginResponseForStorage(payload:any){
        console.log(payload);
        const userDetails = new UserDetails(payload.firstName, payload.lastName, payload.email, payload.picture? payload.picture : undefined);
        const loginDetails = new LoginDetails(payload.permissionLevel, userDetails);
        return loginDetails
    }
    
}