import * as React from 'react';
import {createContext, useContext, useReducer} from 'react';
import { ILoginDetailsProps } from '../../Helpers/AuthInterface';

interface IAuthState{
    LoginDetails:ILoginDetailsProps | null
}

type Action = 
| {type: 'SET_USER'; payload:ILoginDetailsProps}
| {type: 'REMOVE_USER';}

const initialState: IAuthState = {LoginDetails:null}

const authReducer = (state:IAuthState, action: Action): IAuthState => {

    switch(action.type){
        case 'SET_USER':
            return {LoginDetails:action.payload};
        case 'REMOVE_USER':
            return {LoginDetails:null};
        default:
            return state;
    }

};

const AuthContext = createContext<{
    state:IAuthState;
    dispatch: React.Dispatch<Action>;
} | undefined>(undefined);

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if(!context){
        throw new Error('useAuthContext must be used within an AuthProvider');
    }
    return context
}

interface AuthContextProps{
    children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthContextProps> = ({children}) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    return(
        <AuthContext.Provider value={{state, dispatch}}>
            {children}
        </AuthContext.Provider>
    )
}