import * as React from 'react';
import { useAuthContext } from './Context Providers/AuthContextProvider';
import { Navigate } from "react-router-dom";

interface IProtectedRoutesProps{
    children:React.ReactNode;
}

function ProtectedRoutes(props:IProtectedRoutesProps){
    const {state} = useAuthContext();

    if(state.LoginDetails){
        return(
            <>{props.children}</>
        );
    }else{
        return(<Navigate to='/login'/>);
    }
}

export default ProtectedRoutes