import axios, { AxiosResponse, HttpStatusCode } from "axios";

export interface ILoginPayloadProps{
    email: string;
    password: string
}

export interface IBuildResponseProps{
  status:HttpStatusCode;
  message:string;
  success:boolean;
  headers?:any;
  data?:any;
}

axios.defaults.withCredentials = true;

export class Endpoints {
    
  
    
    
    async login(payload: ILoginPayloadProps) {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
        try {
          const response = await axios.post(`${apiUrl}/auth/login`, payload);
          const preparedResponse:IBuildResponseProps = response.data;
          return preparedResponse;
        } catch (error) {
          console.error('There was an error!', error);
          throw error;
        }
        
    }

    async test(): Promise<IBuildResponseProps>{
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      try{
        const response = await axios.get(`${apiUrl}/auth/test`);
        const preparedResponse:IBuildResponseProps = response.data;
        return preparedResponse;
      }catch(error){
        throw error
      }
    }
}