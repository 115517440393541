import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Endpoints, IBuildResponseProps } from '../Helpers/ApiEndpoints';
import { useAuthContext } from '../Reusable Components/Context Providers/AuthContextProvider';
import { DataPrepare } from '../Helpers/DataPrepare';
import { useNavigate } from 'react-router-dom';

function Login(){

  const endpoints = new Endpoints();

  async function testapi(){
    try{
      let response:IBuildResponseProps = await endpoints.test();
      console.log(response.data);
    }catch(error){
      console.log(error);
      throw new Error('An error occurred when communicating with the API.');
    }
  }
  
  useEffect(()=>{
    testapi();
  },[]);

    return(
        <div className='loginPageWrapper' style={{display:'flex', width:'100%', height:'100vh', justifyContent:'center', alignItems:'center', flexWrap:'wrap'}}>
            <div className='loginContainer' style={{display:'flex', maxWidth:'400px', maxHeight:'400px', width:'100%', height:'100%', backgroundColor:'lightgray', padding:'20px', boxSizing:'border-box', flexDirection:'column', gap:'30px'}}>
              <h1>Sign Out</h1>
            </div>
        </div>
    );
}

export default Login